<template>
    <div class="content has-text-centered">
        <!-- Page header-->
        <header class="main-title section is-inline-block has-text-left">
            <div class="columns is-vcentered">
                <div class="column">
                    <div class="inner-content">
                        <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile py-0 my-2">
                            {{ $t("message.home.assistant") }}<br />
                            {{ $t("message.home.administrative") }}<br />
                            {{ $t("message.home.digital") }}
                        </h1>
                    </div>
                    <div class="inner-content">
                        <h2 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile mt-3 mb-5">
                            {{ $t("message.home.simple_efficient_safe") }}
                            <img src="../../assets/images/swiss_flag.png" alt="swiss-flag" width="24" height="24" style="vertical-align:middle" />
                        </h2>
                    </div>
                    <div class="inner-content has-text-left">
                        <!--<span class="tag is-seenjab-green mr-2">{{ $t("message.common.available_soon") }}</span>-->
                        <form-button type="button" @click="openSignup()"
                            cssClass="has-text-white has-background-primary is-rounded"
                            :caption="$t('message.home.try_it')" />
                    </div>
                    <h3 class="title nl-title has-text-white has-text-weight-light is-size-3 is-size-4-mobile">{{ $t("message.newsletter.messages.title") }}</h3>
                    <sendinblue-subscribe name="nlHome" />
                </div>
                <div class="column">
                    <img src="../../assets/images/home/assistant.webp" alt="assistant" />
                </div>
            </div>
        </header>

        <!-- Page main content -->
        <main>
            <!-- News section -->
            <section class="latest-news">
                <h2 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("message.home.latest_news") }}</h2>
                <div class="columns">
                    <div class="column" :class="{'is-half': (articles.length > 1)}" v-if="(typeof articles[0] !== 'undefined')">
                        <directus-article from="slug" :getter="articles[0].translations[$i18n.locale].slug" :isLight="true" />
                    </div>
                    <div class="column is-half" v-if="(typeof articles[1] !== 'undefined')">
                        <directus-article from="slug" :getter="articles[1].translations[$i18n.locale].slug" :isLight="true" />
                    </div>
                </div>
            </section>

            <!-- Features section -->
            <section class="section is-fullwidth my-6">
                <h2 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.features") }}</h2>
                <div class="features columns is-vcentered">
                    <div class="column features-list mx-3">
                        <h3 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">{{ $t("message.home.documents") }}</h3>
                        <p class="is-size-4-desktop is-size-5-tablet">
                            {{ $t("message.home.documents_desc") }}
                        </p>
                    </div>
                    <div class="column images has-text-right mx-3">
                        <img class="feature-image" src="../../assets/images/home/admin_assistant.webp" alt="admin_assistant" />
                    </div>
                </div>
            </section>
            <section class="section is-fullwidth my-6">
                <div class="features columns reverse-columns is-vcentered">
                    <div class="column images has-text-left">
                        <img class="feature-image" src="../../assets/images/home/security.webp" alt="security" />
                    </div>
                    <div class="column features-list">
                        <h3 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">{{ $t("message.home.security") }}</h3>
                        <p class="is-size-4-desktop is-size-5-tablet">
                            {{ $t("message.home.security_desc1") }}
                        </p>
                        <p class="is-size-4-desktop is-size-5-tablet">
                            {{ $t("message.home.security_desc2") }}
                        </p>
                    </div>
                </div>
            </section>

            <section class="section is-fullwidth my-6">
                <div class="features columns is-vcentered">
                    <div class="column features-list">
                        <h3 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile py-0 my-2">{{ $t("message.home.accessibility") }}</h3>
                        <p class="is-size-4-desktop is-size-5-tablet">
                            {{ $t("message.home.accessibility_desc") }}
                        </p>
                    </div>
                    <div class="column images has-text-right">
                        <img class="feature-image" src="../../assets/images/home/accessibility.webp" alt="accessibility" />
                    </div>
                </div>
            </section>

            <section class="section is-fullwidth my-6">
                <div class="features columns reverse-columns is-vcentered">
                    <div class="column images has-text-left">
                        <img class="feature-image" src="../../assets/images/home/more.webp" alt="more" />
                    </div>
                    <div class="column features-list">
                        <h3 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile py-0 my-2">{{ $t("message.home.more") }}</h3>
                        <p class="is-size-4-desktop is-size-5-tablet">
                            {{ $t("message.home.more_desc") }}
                        </p>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
/**
 * Home view
 * Display the Home page
 * @author Alba IT
 * @version 1.0.0
 */
import Config from '@/config';

import { getArticles } from '@/scripts/articles';

import FormButton from '@/components/layout/controls/form/FormButton.vue';
import DirectusArticle from '@/components/ext/DirectusArticle.vue';
import SendinblueSubscribe from '@/components/ext/SendinblueSubscribe.vue';

export default {
    name: 'Home',
    components: {
        FormButton,
        DirectusArticle,
        SendinblueSubscribe
    },
    data: function() {
        return {
            articles: [undefined, undefined]
        };
    },
    mounted: function() {
        // Get the last (?sort=-id) 2 articles (&limit=2)
        //getTranslatedArticles(this.$i18n.locale, '?sort=-id&limit=2')

        // Filter static content articles
        const filter = JSON.stringify({
            'article_type_id': {
                '_neq': '3'
            }
        });
        getArticles(`?filter=${filter}&sort=-id&limit=2`)
        .then((result) => {
            this.articles = result;
        });
    },
    methods: {
        openSignup: function() {
            window.open(`${Config.dashboard}/register`, '_blank');
        }
    },
    metaInfo: function() {
        return {
            title: `${this.$t("message.home.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.home.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.home.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.home.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.main-title {
    width: 1000px;
    height: 75vh;
}

.features {
    width: 80%;
    margin: auto;
}

@media(max-width: $tablet-landscape) {
    .main-title {
        width: 100%;
    }
    .features {
        width: 100%;
    }
}

.feature-image {
    width: 75%
}

.latest-news {
    background: rgb(62, 62, 62);
    padding: 30px;
    margin: 20px -32px 0 -32px;
}
</style>
